import React from 'react';
import * as styles from '../styles/pages/home.module.css';

import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Footnotes from '../components/Footnotes/Footnotes';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';

import fourSeasonsImg from '../images/home/4seasons.png';

import { GtagHead } from '../components/GTAG';
import Spikes from '../components/Spikes/Spikes';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>LOQTORZI (toripalimab-tpzi) | Patient website</title>
        <meta name='description'
          content='Patient website of Loqtorzi, the first and only FDA-approved treatment studied in nasopharyngeal cancer (NPC) that has spread or returned. Please see Indication and Important Safety Information'/>
    </>
  )
}

export default function Home() {
  return (
    <Layout className={ styles.homeLayout } darkMode={ true } isHome={ true }>
      <section className={ styles.mainImage }>
        <img className={ styles.image } src={ fourSeasonsImg } alt='Four seasons walking couple'/>
        <div className={ styles.header }>
          <div>
            <GlobalWrapper className={'mobileWide'}>
              <div className={ styles.title }>A CHANCE</div>
              <div className={ styles.subtitle }>
                <span>to walk together <br/>time and time again</span>
              </div>
            </GlobalWrapper>
          </div>
        </div>
        <div className={'mt-auto'}>
          <GlobalWrapper isHome={ true }>
            <Footnotes className={ 'relative !text-[1.2rem] !text-white !leading-[1.25] desktop:max-w-[76rem]' } items={[
              {
                bullet:'*',
                text: <span>LOQTORZI<sup>&reg;</sup> may be used in combination with chemotherapy medicines cisplatin and gemcitabine as your first treatment when your NPC has spread to other parts of your body (metastatic) or has returned (recurrent) in nearby tissues (locally advanced).</span>
              },
              {
                bullet: <sup className='text-[60%]'>†</sup>,
                text: <span>LOQTORZI may be used alone to treat your NPC when it has returned (recurrent) and cannot be removed with surgery <strong>or</strong> has spread (metastatic), <strong>and</strong> you received chemotherapy that contains platinum, and it did not work or is no longer working.</span>
              }
            ]} />
          </GlobalWrapper>
        </div>
      </section>
      <Spikes className={styles.spikes} wrapperClassName={styles.spikesWrapper} isHome={ true }/>
      <GlobalWrapper className={ 'desktop:pb-[8rem]' } isHome={ true }>
        <ColumnsConnections className={'mt-[3.8rem] desktop:mt-[5.6rem] large:mt-[8rem]'}  darkMode={ true }
          data={[
            {
              title: 'Explore the results',
              link: '/loqtorzi-clinical-trial-results',
              text: 'See how LOQTORZI may give you the chance for more time without your cancer progressing.'
            },
            {
              title: 'Get resources',
              link: '/resources',
              text: 'Find educational tools and resources to help support you on your treatment journey.'
            },
            {
              classNameContainer: 'large:!max-w-[26.5rem]',
              title: 'Stay connected',
              link: '/stay-in-touch',
              text: <>Keep yourself updated and informed by joining our community for people living with NPC.</>
            }
          ]}/>
      </GlobalWrapper>
    </Layout>
  );
}
